<template>
  <b-row class="px-1 justify-content-between flex-wrap">
    <b-col cols="12" md="6" class="d-flex justify-content-between justify-content-md-start px-0">
      <!-- Like -->
      <div class="mr-1 social-buttons">
        <button-like
          :liked="likedByMember"
          :disabled="isSending"
          :show-label="!isWidget && !isMobile?true:false"
          :likes-count="isWidget || isMobile? totalLikes : 0"
          @click="like"
        />
      </div>
      <!-- Comment -->
      <div>
        <b-button 
          variant="link" 
          class="line-height-1 d-flex align-items-center p-0 text-dark social-buttons" 
          @click="$emit('comment')"
        >
          <feather-icon icon="MessageSquareIcon" size="18" class="mr-50" />
          <span v-if="!isWidget && !isMobile">{{ $t('social.comment') }}</span>
          <small v-else>{{ commentsCount }}</small>
        </b-button>
      </div>
      <!-- Complete -->
      <!--div class="ml-1">
        <b-button 
          variant="link" 
          class="line-height-1 d-flex align-items-center p-0 text-dark social-buttons" 
          :class="{ 'item-completed': completedByMember }"
          @click="$emit('done')"
        >
          <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" />
          <span v-if="!isWidget && !isMobile">
            {{ completedByMember ? $t('social.completed') : $t('social.complete') }}
          </span>
        </b-button>
      </div-->
    </b-col>
    <b-col
      v-if="!isWidget && !isMobile"
      cols="12"
      md="6"
      class="d-flex justify-content-between justify-content-md-end px-0"
    >
      <div v-if="totalLikes" class="mr-1 social-buttons">
        <b-icon-heart-fill font-scale="1" class="heart-fill" />
        <span class="font-weight-bold">{{ $tc('social.likes', totalLikes, { count: totalLikes }) }}</span>
      </div>
      <div v-if="commentsCount" class="social-buttons">
        <b-button variant="link" class="p-0 text-dark font-weight-bold" @click="$emit('comment')">
          {{ $tc('social.comments', commentsCount, { count: commentsCount }) }}
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BIconHeartFill,
} from 'bootstrap-vue';
import ButtonLike from '@core/components/social/ButtonLike.vue';

export default {
  name: 'SocialButtons',
  components: {
    BRow,
    BCol,
    BButton,
    BIconHeartFill,
    ButtonLike,
  },
  props: {
    isMedia: {
      type: Boolean,
      default: false,
    },
    media: {
      type: Object,
    },
    commentsCount: {
      type: Number,
      default: 0,
    },
    likedByMember: {
      type: Boolean,
      default: false,
    },
    totalLikes: {
      type: Number,
      default: 0,
    },
    isSending: {
      type: Boolean,
      default: false,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 700;
    },
  },
  methods: {
    like() {
      /*     if (!this.isMedia) {
        if (this.likedByMember) {
          this.likedByMember = false;
          this.totalLikes--;
        } else {
          this.likedByMember = true;
          this.totalLikes++;
        }
      } */

      this.$emit('change-like');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.heart-fill {
  color: #da5f5b;
  margin-right: 0.5rem;
}
.dark-icon {
  color: $gray-700;
}
.social-buttons {
  margin: 0.3rem 0 0.5rem 0;
}

.item-completed {
  color: green !important;
  font-weight: 700;
}
</style>
