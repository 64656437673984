<template>
  <div
    class="p-0 button-like d-flex align-items-center justify-content-center pointer"
    :class="{ 'button-like--liked': liked }"
    variant="link"
    @click="toggleLike"
  >
    <feather-icon icon="ThumbsUpIcon" size="18" :class="!showLabel && !likesCount ? 'mr-75' : ''" />
    <span v-if="showLabel" class="ml-50">
      {{ $t('social.like') }}
    </span>
    <small v-if="likesCount" class="likes-count">
      {{ likesCount }}
    </small>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  name: 'ButtonLike',
  components: { BButton },
  model: {
    prop: 'liked',
    event: 'update:liked',
  },
  props: {
    liked: Boolean,
    showLabel: Boolean,
    likesCount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    toggleLike(event) {
      this.$emit('update:liked', !this.liked);
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.button-like {
  color: $dark;
  @include hover() {
    color: $primary;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($dark, .5);
  }

  &.disabled,
  &:disabled {
    color: $text-muted;
  }

/*   &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background: $primary;
    color: $white;
  } */
  &--liked {
    color: $primary;
    @include hover() {
      color: $dark;
    }
    &:focus,
    &.focus {
      box-shadow: 0 0 0 $btn-focus-width rgba($primary, .5);
    }

    &.disabled,
    &:disabled {
      color: $text-muted;
    }

/*     &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background: $dark;
      color: $white;
    } */
  }

  .likes-count {
    display: inline-block;
    line-height: 1.5;
    margin-left: calc($spacer / 2);
  }
}
</style>
