export const COMMENTS_STORE_MODULE_NAME = 'comments';

export const COMMENTS_GETTERS = {
  getPage: `${COMMENTS_STORE_MODULE_NAME}/getPage`,
  getTotal: `${COMMENTS_STORE_MODULE_NAME}/getTotal`,
};

export const COMMENTS_MUTATIONS = {
  updateComment: `${COMMENTS_STORE_MODULE_NAME}/updateComment`,
};

export const COMMENTS_ACTIONS = {
  fetchComments: `${COMMENTS_STORE_MODULE_NAME}/fetchComments`,
  createComment: `${COMMENTS_STORE_MODULE_NAME}/createComment`,
  toggleLike: `${COMMENTS_STORE_MODULE_NAME}/toggleLike`,
};
