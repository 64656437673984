<template>
  <div class="comments-list">
    <div v-if="isLoading">
      <b-spinner class="d-block mx-auto mt-3" />
    </div>
    <template v-else-if="comments.length">
      <comment-item
        v-for="(comment, index) in comments"
        :key="comment.key"
        :comment="comment"
        :class="{ 'pt-1 mt-1': index > 0 }"
        :leaf="leaf"
        :item-type="itemType"
        :source-key="sourceKey"
        @toggle-like="handleToggleLike"
        @commented="handleCommented"
        @deleted="handleDeleteCommented"
      />
    </template>
    <div v-else class="d-flex flex-column align-items-center justify-content-center">
      <b-img center :src="Commentsplaceholder" class="placeholder-size mt-3 d-blosck mx-auto pt-lg-5" />
      <p class="text-center mt-2 placeholder-message">
        {{ $t('social.no-comments') }}
      </p>
    </div>
    <a
      v-if="hasMore"
      class="d-block text-center"
      :class="isLoadingNextPage?'text-muted':'text-primary'"
      @click="handleLoadNextPage"
    >
      <b-spinner v-if="isLoadingNextPage" small class="spinner mr-50" /> {{ $t('action.load-more') }}
    </a>
  </div>
</template>

<script>
import {
  COMMENTS_STORE_MODULE_NAME, COMMENTS_GETTERS, COMMENTS_ACTIONS, COMMENTS_MUTATIONS,
} from '@/views/apps/comments/constants/comments-store-constants';
import { BImg, BSpinner } from 'bootstrap-vue';
import CommentsPlaceholder from '@/assets/images/placeholders/light/comments.svg';
import commentsStoreModule from '@/views/apps/comments/store/commentsStoreModule';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';

// TODO: Extract to BaseVerticalContainer
export default {
  name: 'CommentsList',
  components: {
    // this avoids problems with vue circular references.
    // @see https://vuejs.org/v2/guide/components-edge-cases.html#Circular-References-Between-Components
    CommentItem: () => import('./CommentItem.vue'),
    BImg,
    BSpinner,
  },
  props: {
    sourceKey: {
      type: String,
      required: true,
    },
    morphType: {
      type: String,
      required: true,
    },
    itemType: {
      type: String,
      default: null,
    },
    leaf: Boolean,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      isLoadingNextPage: false,
    };
  },
  computed: {
    Commentsplaceholder() {
      return CommentsPlaceholder;
    },
    comments() {
      if (this.$store.getters.comments[this.sourceKey]) {
        return this.$store.getters.comments[this.sourceKey].unpaginated;
      }
      return [];
    },
    hasMore() {
      const total = this.$store.getters.comments[this.sourceKey]?.meta?.total || 0;
      return this.comments.length < total;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule(COMMENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(COMMENTS_STORE_MODULE_NAME, commentsStoreModule);
    }
  },
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    async handleLoadNextPage() {
      this.currentPage += 1;
      this.isLoadingNextPage = true;
      await this.fetchData();
      this.isLoadingNextPage = false;
    },
    async handleToggleLike(comment) {
      try {
        await this.$store.dispatch('toggleLike', {
          itemType: 'comments',
          storedKey: this.sourceKey,
          key: comment.key,
          morphType: 'comment',
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'comments',
        storedKey: this.sourceKey,
        page: this.currentPage,
        perPage: 16,
        requestConfig: {
          orderByDate: -1,
          morphType: this.morphType,
          key: this.sourceKey,
        },
      });
    },
    handleCommented(targetComment) {
      ++targetComment.childCommentsCount;
    },
    handleDeleteCommented() {
      if (this.leaf) {
        this.$emit('delete');
      } else {
        this.$emit('delete-comment');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.placeholder-size {
    max-width: 120px;
 }
 .placeholder-message {
  color: $primary;
  font-weight: $font-weight-bold;
 }
</style>
