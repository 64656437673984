<template>
  <div>
    <div class="comment-box-container">
      <plain-editor
        ref="plainEditor"
        v-model="content"
        :placeholder="$t('social.write-comment')"
        :disabled="isLoading"
        @change="handleEditorChange"
      />
    </div>
    <div v-if="!isEmpty" class="text-right mt-1">
      <b-button
        variant="link"
        size="sm"
        class="mr-1"
        :disabled="isLoading"
        @click="handleCancel"
      >
        {{ $t("action.cancel") }}
      </b-button>
      <b-button
        variant="primary"
        size="sm"
        :disabled="isLoading"
        @click="handleCommentCreation"
      >
        {{ isEditing?'Editar comentari':$t("social.post-comment") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import PlainEditor from '@core/components/editor/PlainEditor.vue';

import {
  COMMENTS_STORE_MODULE_NAME,
  COMMENTS_ACTIONS,
} from '@/views/apps/comments/constants/comments-store-constants';
import commentsStoreModule from '@/views/apps/comments/store/commentsStoreModule';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

import { BButton } from 'bootstrap-vue';

export default {
  name: 'CommentForm',
  components: { PlainEditor, BButton },
  props: {
    sourceKey: {
      type: String,
      required: true,
    },
    morphType: {
      type: String,
      required: true,
    },
    itemType: {
      type: String,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      content: '',
      isEmpty: true,
      isLoading: false,
    };
  },
  created() {
    if (!this.$store.hasModule(COMMENTS_STORE_MODULE_NAME)) {
      this.$store.registerModule(
        COMMENTS_STORE_MODULE_NAME,
        commentsStoreModule,
      );
    }
    if (this.isEditing) {
      this.content = this.comment.content;
      this.isEmpty = false;
    }
  },
  methods: {
    focus() {
      this.$refs.plainEditor.focus();
    },
    handleCancel() {
      this.content = '';
      this.$emit('cancel');
    },
    handleEditorChange({ text }) {
      this.isEmpty = text.trim().length === 0;
    },
    async handleCommentCreation() {
      try {
        if (this.isEditing) {
          this.isLoading = true;
          const item = await this.$store.dispatch('editItem', {
            noSet: true,
            item: {
              itemType: 'comments',
              storedKey: this.sourceKey,
              requestConfig: {
                comment: this.content,
                key: this.comment.key,
                morphType: this.morphType,
              },
            },
          });
          this.isLoading = false;
          this.$emit('update');
          this.content = '';
          if (this.itemType != null) {
            this.$socket.emit('updateData', {
              itemType: 'comments',
              storedKey: this.sourceKey,
              key: this.comment.key,
              data: item.data,
              communitySlug: this.$store.getters.currentCollective.slug,
            });
          }
        } else {
          this.isLoading = true;
          const item = await this.$store.dispatch('createItem', {
            noSet: true,
            item: {
              itemType: 'comments',
              storedKey: this.sourceKey,
              requestConfig: {
                comment: this.content,
                key: this.sourceKey,
                morphType: this.morphType,
              },
            },
          });
          this.content = '';
          this.isLoading = false;
          if (this.itemType != null) {
            this.$socket.emit('newData', {
              itemType: 'comments',
              storedKey: this.sourceKey,
              data: item.data,
              communitySlug: this.$store.getters.currentCollective.slug,
            });
            this.$emit('created');
          }
        }
      } catch {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
  /*   async handleEditComment() {
      console.log(this.isEditing);
      try {
        this.isLoading = true;
        const item = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'comments',
            storedKey: this.sourceKey,
            requestConfig: {
              comment: this.content,
              key: this.comment.key,
              morphType: this.morphType,
            },
          },
        });
        this.content = '';
        this.isLoading = false;
        if (this.itemType != null) {
          this.$socket.emit('updateData', {
            itemType: 'comments',
            storedKey: this.sourceKey,
            key: this.comment.key,
            data: item.data,
            communitySlug: this.$store.getters.currentCollective.slug,
          });
          this.$emit('update');
        }
      } catch {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    }, */
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

.comment-box-container {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 1rem;
}

.post-creation-form {
  &__text-editor::v-deep {
    .ql-editor {
      min-height: 1rem;
    }
  }
}
</style>
