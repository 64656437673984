import Vue from 'vue';
import Service from '@/config/service-identifiers';
import store from '@/store';
import OrderBy from '@/@core/constants/OrderBy';
import {
  getByKey,
  getPage,
  getTotal,
  prependItem,
  storePage,
  updateItemByKey,
} from '@/store/store-utils/StorageUtils';

/**
 * Social Store Module
 */
export default {
  namespaced: true,
  state: {},
  getters: {
    getPage: (state) => (
      ({
        morphType,
        key,
        page,
        perPage,
      }) => (
        getPage({ page, perPage }, state, [morphType, key])
      )
    ),
    getTotal: (state) => ({ morphType, key }) => getTotal(state, [morphType, key]),
  },
  mutations: {
    storePage(state, {
      morphType, key, data, meta,
    }) {
      storePage({ data, meta }, state, [morphType, key]);
    },
    storeComment(state, {
      morphType, key, comment,
    }) {
      prependItem(comment, state, [morphType, key]);
    },
    updateComment(state, {
      morphType, key, commentKey, partial,
    }) {
      updateItemByKey(commentKey, partial, state, [morphType, key]);
    },
    toggleLike(state, { morphType, key, commentKey }) {
      const comment = getByKey(commentKey, state, [morphType, key]);
      Vue.set(comment, 'likedByMember', !comment.likedByMember);
      Vue.set(comment, 'totalLikes', comment.totalLikes + (comment.likedByMember ? +1 : -1));
    },
  },
  actions: {
    /**
     * Returns the list of comments for object identified byt 'key', and of type 'morphType'.
     * MorphTpe debe ser @copernicsw/community-common/commonType/Morph
     */
    async fetchComments(
      { commit, getters },
      {
        key, morphType, page, perPage, force,
      },
    ) {
      const storedPage = getters.getPage({
        key, morphType, page, perPage,
      });
      if (storedPage && !force) {
        return storedPage;
      }

      const response = await store.$service[Service.BackendClient].get('comments', {
        params: {
          morphType,
          key,
          orderByDate: OrderBy.DESC,
        },
      });

      commit('storePage', { morphType, key, ...response.data });

      return getters.getPage({
        morphType, key, page, perPage,
      });
    },
    async createComment(
      { commit, rootGetters, rootState },
      { content, key, morphType },
    ) {
      const communityKey = rootState.collective.subcollective.key || rootState.collective.collective.key;
      const response = await store.$service[Service.BackendClient].post('comments', {
        morphType,
        key,
        comment: content,
        communityKey,
      });

      const comment = {
        key: response.data.key,
        content,
        childCommentsCount: 0,
        createdAt: new Date(),
        createdBy: rootGetters.loggedUser,
        totalLikes: 0,
        likedByMember: false,
      };

      commit('storeComment', { morphType, key, comment });

      return comment;
    },
    async toggleLike(
      { commit, rootState },
      {
        commentKey, liked, key, morphType,
      },
    ) {
      const communityKey = rootState.collective.collective.slug;
      const path = liked ? 'takeLike' : 'giveLike';
      commit('toggleLike', { commentKey, key, morphType });
      
      try {
        await store.$service[Service.BackendClient].post(path, {
          morphType: 'comment',
          key: commentKey,
          communityKey,
        });
      } catch (error) {
        commit('toggleLike', { commentKey, key, morphType });

        throw error;
      }
    },
  },
};
